<template>
  <div id="container" v-if="fetch_data">
    <section id="event">
      <div class="wrap">
        <div class="cts_box">
          <h2 class="sub_ttl"><span v-html="queryMarker(htmlEscape(model.title), query)"></span></h2>
          <ul class="class_tag_list">
            <span class="tag class01" v-if="model.rank_regular_plus">本科プラス</span>
            <span class="tag class02" v-if="model.rank_regular">本科</span>
            <span class="tag class03" v-if="model.rank_preparatory">予科</span>
            <span class="tag class04" v-if="model.rank_general">一般</span>
            <span class="tag class05" v-if="model.rank_student">学生科</span>
            <span class="tag class06" v-if="false /*model.rank_corporation*/">法人科</span>
          </ul>
          <div class="event_data">
            <p class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }}</span><span
                class="time">{{ model.open_time }}</span></p>
            <p class="lead_txt" v-html="model.list_text"></p>
            <div class="detail flex" v-if="model.held_type === 'held_type_normal'">
              <span class="tag">中継会場</span>{{ model.relay_place }}
            </div>
            <div class="free_area" v-html="queryMarker(model.description, query)"></div>

            <!-- 暫定 抽選研修会なら申し込み関連を出さない -->
            <template v-if="!isLottery()">
              <div class="btn_wrap event_cv" v-if="model.held_type === 'held_type_normal'">
                <p class="alnC sp_alnL mt10 txc_b" v-if="!model.live_active">
                  オンラインの場合は事前申し込みは不要です。<!--<br>中継日時になりましたらこちらからご覧ください。-->
                </p>
                <p class="alnC sp_alnL mt10 txc_b" v-if="model.live_active">
                  オンラインの場合は事前申し込みは不要です。<br>中継日時になりましたら
                  <router-link :to="{ name: 'GetsureiBroadcast', params: {id: model.id}}">こちら</router-link>
                  からご覧ください。
                </p>
              </div>

              <div class="btn_wrap event_cv" v-if="model.request_enabled">
                <p>参加のお申込みはこちらから</p>
                <p class="caution" v-if="model.rank_regular_plus_request_enabled"> 現在「本科プラス」塾生の優先申し込み期間です。</p>
                <a href="javascript:void(0)" v-bind:class="{disabled: model.close || model.joined}" @click="joinClicked"
                   class="submit basic arrow">
                  <template v-if="model.held_type === 'held_type_normal'">公開放送・懇親会申し込み</template>
                  <template v-else-if="model.joined">参加申し込み済み</template>
                  <template v-else>参加申し込み</template>
                </a>
                <p class="limit">参加申込期限：{{ moment(model.live_close_date, 'YYYY/MM/DD (ddd) HH:mm') }} まで</p>
                <!--受付終了時-->
                <p class="limit" v-if="!model.joined && model.close">受付は終了しました。</p>
                <p class="limit" v-if="model.joined">申し込み済みです。</p>
              </div>

              <div class="btn_wrap event_cv" v-else-if="model.lottery_enabled">
                <!-- 抽選中 -->
                <p v-if="!model.joined">参加のお申込みはこちらから</p>
                <p class="caution" v-if="model.rank_regular_plus_request_enabled">
                  現在「本科プラス」塾生の優先申し込み期間です。
                </p>

                <p class="caution">
                  {{ model.lottery_lead_text }}
                </p>

                <a href="javascript:void(0)"
                   v-bind:class="{disabled: model.join_contents_close || model.close || model.joined}"
                   @click="joinClicked"
                   class="submit basic arrow">公開放送・懇親会の抽選に申し込む
                </a>
                <p class="limit">参加申込期限：{{ moment(model.close_date, 'YYYY/MM/DD (ddd) HH:mm') }} まで</p>
                <p class="limit" v-if="model.joined">申し込み済みです。</p>
                <p class="limit" v-else-if="model.join_contents_close || model.close">申し込み受付は終了いたしました。</p>
              </div>
              <div class="btn_wrap event_cv" v-else>
                <p class="caution" v-if="model.rank_regular_plus_request_enabled"> 現在「本科プラス」塾生の優先申し込み期間です。<br>
                  通常受付開始は下記の日時となります。 </p>
                <a href="" class="submit light disabled">申し込み受付前です</a>
                <p class="limit">受付開始日：{{ moment(model.request_start, 'YYYY/MM/DD (ddd) HH:mm') }}</p>
              </div>

            </template>
          </div>
        </div>

        <div class="cts_box" v-if="model.pdf_file_url && model.joined">
          <div class="mov_archves_list bdr_none">
            <h3 class="min_ttl">教材ダウンロード</h3>
            <div class="mov_archives flex">
              <a v-bind:href="model.pdf_file_url" class="flex" target="_blank">
                <div class="thumb"><img src="/common/images/thumb_pdf.png" alt=""></div>
                <div class="info" v-html="model.pdf_text"></div>
              </a>
            </div>
          </div>
        </div>

        <div class="cts_box" v-if="false">
          <h3 class="min_ttl">概要</h3>
          <div class="df_table">
            <dl class="flex">
              <dt>開催日</dt>
              <dd>{{ moment(model.open_date, 'YYYY/MM/DD (ddd)') }} {{ model.open_time }}</dd>
            </dl>
            <dl class="flex" v-if="model.instructor">
              <dt>講師</dt>
              <dd v-html="model.instructor"></dd>
            </dl>
            <dl class="flex">
              <dt>現地参加募集</dt>
              <dd>
                先着順 {{ model.sales_count }}名<br>
                <span class="red" v-if="model.full">※満席のため、現地参加お申込みを締め切りました。生配信をご覧ください。</span><!--販売数超えたら表示？-->
              </dd>
            </dl>
            <dl class="flex">
              <dt>参加資格</dt>
              <dd>複眼経済塾塾生の方
                <template v-if="model.rank_regular_plus">（本科プラス）</template>
                <template v-if="model.rank_regular || model.rank_regular_default">（本科）</template>
                <template v-if="model.rank_preparatory">（予科）</template>
                <template v-if="model.rank_student">（学生科）</template>
                <template v-if="false /*model.rank_corporation*/">（法人科）</template>
              </dd>
            </dl>
            <dl class="flex">
              <dt>申込期限</dt>
              <dd>{{ moment(model.live_close_date, 'YYYY/MM/DD (ddd) HH:mm') }}</dd>
            </dl>
            <dl class="flex"
                v-if="model.price_rank_regular_plus !== null || model.price_rank_regular !== null ||
                model.price_rank_regular_default !== null || model.price_rank_preparatory !== null ||
                model.price_rank_student !== null || model.price_rank_corporation !== null">
              <dt>価格</dt>
              <dd>
                <div class="price" v-bind:class="{'active': current_user.sns_rank === 'rank_regular_plus'}"
                     v-if="model.price_rank_regular_plus !== null">
                  <span class="class">本科プラス：</span>
                  <span v-if="model.rank_regular_plus">¥{{ delimited(model.price_rank_regular_plus) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price" v-if="!isRegularDefault() && model.price_rank_regular !== null"
                     v-bind:class="{'active': current_user.sns_rank === 'rank_regular'}">
                  <span class="class">本科：</span>
                  <span v-if="model.rank_regular">¥{{ delimited(model.price_rank_regular) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price active" v-if="isRegularDefault() && model.price_rank_regular_default !== null"
                     v-bind:class="{'active': current_user.sns_rank === 'rank_regular_default'}">
                  <span class="class">本科：</span>
                  <span v-if="model.rank_regular_default">¥{{ delimited(model.price_rank_regular_default) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price" v-bind:class="{'active': current_user.sns_rank === 'rank_preparatory'}"
                     v-if="model.price_rank_preparatory !== null">
                  <span class="class">予科：</span>
                  <span v-if="model.rank_preparatory">¥{{ delimited(model.price_rank_preparatory) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price" v-bind:class="{'active': current_user.sns_rank === 'rank_student'}"
                     v-if="model.price_rank_student !== null">
                  <span class="class">学生科：</span>
                  <span v-if="model.rank_student">¥{{ delimited(model.price_rank_student) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <div class="price" v-bind:class="{'active': current_user.sns_rank === 'rank_corporation'}"
                     v-if="model.price_rank_corporation !== null">
                  <span class="class">法人科：</span>
                  <span v-if="model.rank_corporation">¥{{ delimited(model.price_rank_corporation) }}</span>
                  <span v-else>参加できません</span>
                </div>
                <br>
                <br>
                ※ただし塾生のみ参加可<br>
                <template v-if="model.held_type === 'held_type_normal'">
                  （オンラインで生中継をご視聴いただく場合は申込は必要ありません。 ）
                </template>
              </dd>
            </dl>
            <dl class="flex" v-if="model.place_text">
              <dt>中継会場</dt>
              <dd v-html="model.place_text"></dd>
            </dl>
            <dl class="flex" v-if="model.note_text">
              <dt>備考</dt>
              <dd v-html="model.note_text"></dd>
            </dl>
          </div>
        </div>

        <div id="event_map" class="cts_box">
          <div v-if="model.map_tag">
            <h3 class="min_ttl" v-if="model.held_type === 'held_type_normal'">会場周辺の地図</h3>
            <h3 class="min_ttl" v-else>地図</h3>
            <div class="g_map_wrap" v-html="model.map_tag"></div>
          </div>

          <!-- 暫定 抽選研修会なら申し込み関連を出さない ボタン重複の為非表示 -->
          <template v-if="false">
            <div class="btn_wrap event_cv" v-if="model.held_type === 'held_type_normal'">
              <p class="alnC sp_alnL mt10 txc_b" v-if="!model.live_active">
                オンラインの場合は事前申し込みは不要です。<!--<br>中継日時になりましたらこちらからご覧ください。-->
              </p>
              <p class="alnC sp_alnL mt10 txc_b" v-if="model.live_active">
                オンラインの場合は事前申し込みは不要です。<br>中継日時になりましたら
                <router-link :to="{ name: 'GetsureiBroadcast', params: {id: model.id}}">こちら</router-link>
                からご覧ください。
              </p>
            </div>

            <div class="btn_wrap event_cv" v-if="!model.request_enabled">
              <p class="caution" v-if="model.rank_regular_plus_request_enabled"> 現在「本科プラス」塾生の優先申し込み期間です。<br>
                通常受付開始は下記の日時となります。</p>
              <a href="" class="submit light disabled">申し込み受付前です</a>
              <p class="limit">受付開始日：{{ moment(model.request_start, 'YYYY/MM/DD (ddd) HH:mm') }}</p>
            </div>
            <div class="btn_wrap event_cv" v-if="model.request_enabled">
              <p>参加のお申込みはこちらから</p>
              <p class="caution" v-if="model.rank_regular_plus_request_enabled"> 現在「本科プラス」塾生の優先申し込み期間です。</p>
              <a href="javascript:void(0)" v-bind:class="{disabled: model.close || model.joined}" @click="joinClicked"
                 class="submit basic arrow">
                <template v-if="model.held_type === 'held_type_normal'">公開放送・懇親会申し込み</template>
                <template v-else-if="model.joined">参加申し込み済み</template>
                <template v-else>参加申し込み</template>
              </a>
              <p class="limit">参加申込期限：{{ moment(model.live_close_date, 'YYYY/MM/DD (ddd) HH:mm') }} まで</p>
              <!--受付終了時-->
              <p class="limit" v-if="!model.joined && model.close">受付は終了しました。</p>
              <p class="limit" v-if="model.joined">申し込み済みです。</p>
            </div>
          </template>
        </div>
      </div>
    </section>

    <JoinAllowGeneralModal v-bind:target_id="id" v-if="show_join_allow_general_modal"
                           v-on:cancel="show_join_allow_general_modal = false"/>
    <JoinDenyGeneralModal v-bind:target_id="id" v-if="show_join_deny_general_modal"
                          v-on:cancel="show_join_deny_general_modal = false"/>

    <JoinDenyUserlModal v-bind:model="model" v-if="show_join_deny_user_modal"
                        v-on:cancel="show_join_deny_user_modal = false"/>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import JoinAllowGeneralModal from "@/views/site/getsurei/elements/_join_allow_general_modal";
import JoinDenyGeneralModal from "@/views/site/getsurei/elements/_join_deny_general_modal";
import JoinDenyUserlModal from "@/views/site/getsurei/elements/_join_deny_user_modal";

export default {
  mixins: [Libraries],
  components: {
    JoinAllowGeneralModal,
    JoinDenyGeneralModal,
    JoinDenyUserlModal
  },
  data() {
    return {
      id: this.$route.params.id,
      fetch_data: null,
      model: null,
      show_join_allow_general_modal: false,
      show_join_deny_general_modal: false,
      show_join_deny_user_modal: false,
      query: this.$route.query.query
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    '$route'() {
      this.id  = this.$route.params.id
      this.fetch_data = this.model = null
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}getsurei/page/${this.id}.json`, {
            params: {
              draft: this.$route.query.draft,
            }
          })
          .then(response => {
            this.fetch_data = response.data
            this.model = response.data.monthly_live
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },
    joinClicked() {
      // クラス変更の申し込み中で変更後に開催される場合、参加不可とする
      if (this.current_user.change_rank_at && this.current_user.change_rank_at <= this.model.open_date) {
        alert('クラス変更の予約中のため、申し込みが出来ません。クラス変更後に申し込みいただくか、事務局にご相談ください。')
        return
      }

      if (this.model.join_rank) {
        if (this.isUserSignedIn()) { // ログイン中
          this.$router.push({name: 'GetsureiForm', params: {id: this.model.id}, query: this.$route.query})
        } else {
          this.show_join_allow_general_modal = true
        }
      } else {
        if (this.isUserSignedIn()) { // ログイン中
          this.show_join_deny_user_modal = true
        } else { // 非ログイン中
          this.show_join_deny_general_modal = true
        }
      }
    },
    // 暫定 募集研修会か
    isLottery() {
      return this.model.description.substring(0, 14) === "<!-- ${抽選} -->"
    }
  }
}
</script>
